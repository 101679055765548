<template>
  <b-container class="container-content card-container shadow-lg translateYg">
    <div class="page-header">
      <h4 class="page-header-text">{{ headerText }}</h4>
    </div>
    <div>
      <div class="text-center mb-4" id="bankTransfer">
        <h4>
          {{ $t('booking_review.your_payment_slip_has_been_submitted_successfully') }}
        </h4>

        <p>
          {{ $t('booking_review.your_payment_will_be_reviewed_soon') }}
        </p>
        <b-row class="my-3 justify-content-center">
          <b-card
            tag="article"
            class="mb-2"
            style="width: 900px; border-radius: 15px !important"
            border-variant="dark"
          >
            <b-card-text class="mb-0">
              <b-row>
                <b-col cols="6" class="pr-1 text-right">
                  <p>{{ $t('booking_review.amount_to_be_transferred') }}</p>
                </b-col>
                <b-col cols="6" class="ml-0 pl-1 text-primary text-left">
                  <p>
                    ฿ {{ reduce | toCurrencyNumber }}
                    {{ $t('booking_review.total') }}
                  </p>
                </b-col>
              </b-row>
              <!-- <b-row>
                <b-col cols="6" class="pr-1 text-right">
                  <p>{{ $t('booking_review.bank_name') }}</p>
                </b-col>
                <b-col cols="6" class="ml-0 pl-1 text-primary text-left">
                  <p>{{ $t('booking_review.kasikorn') }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" class="pr-1 text-right">
                  <p>{{ $t('booking_review.account_number') }}</p>
                </b-col>
                <b-col cols="6" class="ml-0 pl-1 text-primary text-left">
                  <p>{{ $t('booking_review.058-1-98209-7') }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" class="pr-1 text-right">
                  <p>{{ $t('booking_review.account_name') }}</p>
                </b-col>
                <b-col cols="6" class="ml-0 pl-1 text-primary text-left">
                  <p>{{ $t('booking_review.blue_services') }}</p>
                </b-col>
              </b-row> -->
            </b-card-text>
            <!-- <b-img
              v-if="slip"
              center
              :src="slip"
              height="200"
              class="mb-3"
              style="border-radius: 10px"
              @click="showImage(slip)"
            ></b-img> -->
            <b-row class="justify-content-center">
              <b-col xl="10">
                <div v-html="$t('booking_confirm.after_payment_confirmed')"></div>
              </b-col>
            </b-row>
            <!-- <b-button
              href="#"
              variant="primary"
              @click="uploadImage"
              :disabled="isLoading"
            > -->
            <!-- <b-spinner small class="mr-2" v-if="isLoading"></b-spinner>
              {{ $t("booking_review.upload_payment_slip") }}</b-button
            > -->
            <!-- <input
              id="main_image"
              class="d-none"
              type="file"
              name="photo"
              @change="importMainImage($event)"
            /> -->
            <!-- <p v-if="image && slip" class="mt-3">{{ image.name }}</p> -->
          </b-card>
        </b-row>
        <b-row class="justify-content-center">
          <b-button href="#" variant="primary" @click="toMyBooking">
            {{ $t('button.return_to_my_booking') }}</b-button
          >
        </b-row>
        <!-- <b-row class="justify-content-center">
          <b-button
            href="#"
            variant="primary"
            @click="uploadImage"
            :disabled="!slip"
          >
            {{ $t("booking_review.submit") }}</b-button
          >
        </b-row> -->
      </div>
    </div>

    <b-row>
      <b-col xl="6" class="px-4">
        <div class="d-flex mb-3">
          <img
            :src="bookingReview.maid.user.image_url"
            class="avatar mr-4"
            v-if="bookingReview.method === 1"
          />
          <div>
            <p class="fullname text-grey mt-4" v-if="bookingReview.method === 1">
              {{ fullName }}
            </p>
          </div>
        </div>
        <div>
          <p class="date text-grey" v-if="startDateLabel">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-calendar mb-1 mr-1"
              viewBox="0 0 16 16"
            >
              <path
                d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"
              />
            </svg>
            {{ startDateLabel }}
          </p>
          <p class="cleaning-type text-grey">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-clock mb-1 mr-1"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"
              />
              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
            </svg>
            {{ serviceTime }}
          </p>
          <p class="cleaning-type text-grey">
            <img src="@/assets/images/icons/broom.svg" width="20" height="20" class="mb-1" />
            {{ service }}
          </p>
          <div class="cleaning-type text-grey d-flex">
            <div class="mr-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-geo-alt-fill mb-1 mr-1"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
                />
              </svg>
            </div>
            <div>{{ location }} {{ place }}</div>
          </div>
          <div v-if="additional_details" class="cleaning-type text-grey d-flex">
            <img
              :src="require('@/assets/images/icons/notepad.svg')"
              width="20"
              height="20"
              class="mr-1"
            />
            <div>{{ additional_details }}</div>
          </div>
        </div>
        <!-- <address-detail :address="bookingReview.location"></address-detail> -->

        <!-- MAP -->
        <!-- <div id="map" ref="gmaps" style="height: 300px" class="my-2"></div> -->
        <div class="map-container">
          <iframe
          v-if="true"
          class="my-2 map-iframe"
          width="100%"
          height="300px"
          :src="`https://maps.google.com/maps?q=${bookingReview.location.latitude}, ${bookingReview.location.longitude}&t=&z=14&ie=UTF8&iwloc=&output=embed`"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>  
        </div>
        <p class="cleaning-type text-grey">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-info-circle mb-1 mr-1"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
            />
          </svg>
          {{ $t('form_label.additional_info') }}
        </p>
        <b-form-group class="text-grey mb-0">
          <ol class="mb-0">
            <li v-for="(a, i) in additional" :key="`additional-${i}`" class="col-8">
              {{ a }}
            </li>
          </ol>
        </b-form-group>
        <!-- <div class="w-100"> -->
        <p class="cleaning-type text-grey description">
          {{ bookingReview.description || `-` }}
        </p>
        <b-col lg="12" class="pb-4 pt-2 px-0">
          <p :class="{ 'text-danger': !equipment.bring_equipment }">{{ equipment.text }}</p>
        </b-col>
      </b-col>

      <b-col xl="6" class="px-4">
        <div>
          <div class="d-flex justify-content-between font-weight-bold underline mb-3">
            <p class="text-black">{{ $t('booking_review.booking') }}</p>
            <p>{{ bookingType }}</p>
          </div>
        </div>
        <BookedCredits v-if="!isBookingOneTime" />
        <div>
          <div class="d-flex justify-content-between font-weight-bold underline mb-3">
            <p class="text-black">{{ bookingReview.duration.value }} {{ $t('alias.hour') }}</p>
            <p>฿ {{ amount | toCurrencyNumber }}</p>
          </div>
        </div>
        <div>
          <div class="d-flex justify-content-between font-weight-bold underline mb-3">
            <p class="text-black">{{ $t('booking_review.discount') }}</p>
            <p>฿ {{ totalDiscount > 0 ? '-' : '' }} {{ totalDiscount | toCurrencyNumber }}</p>
          </div>
        </div>
        <div>
          <div class="d-flex justify-content-between font-weight-bold underline mb-3">
            <p class="text-black">{{ $t('booking_review.vat') }}</p>
            <p>฿ {{ vat | toCurrencyNumber }}</p>
          </div>
        </div>
        <div>
          <div class="d-flex justify-content-between font-weight-bold underline mb-3">
            <p class="text-black">
              {{ $t('alias.total') }}
            </p>
            <p>฿ {{ reduce | toCurrencyNumber }}</p>
          </div>
        </div>
      </b-col>
    </b-row>
    <BaseModal id="warning-cannotselectday" size="lg">
      <div class="text-center py-5">
        <p>{{ $t('warning.you_have_booked_within_24_hour') }} {{ time_can_pay }}</p>
      </div>
    </BaseModal>
    <BaseModal id="slip-img" size="lg" centerd="true">
      <b-img
        center
        :src="image"
        alt="Center image"
        height="450"
        class="mt-3 mb-5 mx-0 px-0 width-image-modal"
        style="border-radius: 10px"
      ></b-img>
    </BaseModal>
    <div v-if="isBank && !isSubmit">
      <hr class="mt-4 mb-5" />
      <div class="text-center" id="bankTransfer">
        <h5 class="text-primary">
          {{ $t('booking_review.the_booking_has_not_been_made_yet') }}
        </h5>

        <u>
          {{ $t('booking_review.please_upload_your_payment_slip_to_complete_your_booking') }}
        </u>

        <h5 class="my-3">
          {{ $t('booking_review.time_limit') }}
          <span style="font-size: 28px">{{ countDownHour }} </span>
          ( {{ timeAt }} )
        </h5>

        <b-row class="my-3 justify-content-center">
          <b-card
            tag="article"
            class="mb-2"
            style="width: 900px; border-radius: 15px !important"
            border-variant="dark"
          >
            <b-card-text class="mb-0">
              <b-row>
                <b-col cols="6" class="pr-1 text-right">
                  <p>
                    {{ $t('booking_review.amount_to_be_transferred') }}
                  </p>
                </b-col>
                <b-col cols="6" class="ml-0 pl-1 text-primary text-left">
                  <p>
                    ฿ {{ totalString | toCurrencyNumber }} {{ $t('booking_review.total')
                    }}{{ $t('booking_review.including_vat') }}
                  </p>
                  <!-- <p>{{ $t("booking_review.including_vat") }}</p> -->
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" class="pr-1 text-right">
                  <p>{{ $t('booking_review.bank_name') }}</p>
                </b-col>
                <b-col cols="6" class="ml-0 pl-1 text-primary text-left">
                  <p>{{ $t('booking_review.kasikorn') }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" class="pr-1 text-right">
                  <p>{{ $t('booking_review.account_number') }}</p>
                </b-col>
                <b-col cols="6" class="ml-0 pl-1 text-primary text-left">
                  <p>{{ $t('booking_review.058-1-98209-7') }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" class="pr-1 text-right">
                  <p>{{ $t('booking_review.account_name') }}</p>
                </b-col>
                <b-col cols="6" class="ml-0 pl-1 text-primary text-left">
                  <p>{{ $t('booking_review.blue_services') }}</p>
                </b-col>
              </b-row>
            </b-card-text>
            <b-img
              v-if="image"
              center
              :src="image"
              height="200"
              alt="Center image"
              class="mb-3"
              style="border-radius: 10px"
              @click="showImage(image)"
            ></b-img>

            <b-button
              href="#"
              variant="primary"
              @click="uploadImage"
              :disabled="isLoading"
              class="width_180"
            >
              <b-spinner small class="mr-2" v-if="isLoading"></b-spinner>
              {{ $t('booking_review.upload_payment_slip') }}</b-button
            >
            <input
              id="main_image"
              class="d-none"
              type="file"
              accept="image/*"
              name="photo"
              @change="importMainImage($event)"
            />
            <!-- <p v-if="image" class="mt-3">{{ image.name }}</p> -->
          </b-card>
        </b-row>
        <b-row class="justify-content-center">
          <b-button
            href="#"
            variant="primary"
            @click="toTop"
            class="width_180"
            :disabled="!image || cannotSubmit"
          >
            {{ $t('booking_review.submit') }}</b-button
          >
        </b-row>
      </div>
    </div>
  </b-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { Vue, Component, Watch } from 'vue-property-decorator'
import MaidDetail from '../../components/MaidDetail'
import AddressDetail from '../../components/Address'
import Moment from 'moment'
import ApiClient from '../../services'
import { MERCHANT_ID, PAYSOLUTION_URL, getReturnURL } from '../../config/constants'
import Constant, { ENABLE_APPLY_COUPON } from '../../config/booking'
import { post, isProduction, clone } from '../../utils'
import BookedCredits from '../../components/BookedCredits'
import { getPaySolutionCallbackURL, getEnvName } from '../../helpers/network'
import BaseModal from '../../components/Modal/BaseModal'
import { isUATEnv } from '../../helpers/network'
import Compressor from 'compressorjs'

import { equipmentMixin } from '../../mixins/booking/equipmentMixin'

import get from 'lodash/get'

const host = window.location.host
const env = getEnvName(host)

const BookingStore = createNamespacedHelpers('booking')
const UserStore = createNamespacedHelpers('user')
const AuthStore = createNamespacedHelpers('auth')
const postbackurl = getPaySolutionCallbackURL(env)
const returnurl = getReturnURL()

@Component({
  computed: {
    ...BookingStore.mapState(['bookingReview', 'isLog', 'slip_url', 'reduce', 'discount_price']),
    ...BookingStore.mapState({
      currentPaymentBody: 'paymentBody',
    }),
    ...UserStore.mapState(['email', 'is_verified_phone']),
    ...AuthStore.mapState(['callBackPathAfterWelcomePage', 'isLoggedIn']),
  },
  methods: {
    ...BookingStore.mapMutations(['setPaymentBody', 'setBookingReview', 'setDiscountPrice']),
    ...AuthStore.mapMutations(['setCallBackPathAfterWelcomePage']),
    ...AuthStore.mapActions(['signIn']),
    ...UserStore.mapActions(['getUserProfile']),
    ...BookingStore.mapActions(['processBooking', 'preBooking', 'uploadSlip']),
  },
  components: {
    MaidDetail,
    AddressDetail,
    BookedCredits,
    BaseModal,
  },
  mixins: [equipmentMixin],
})
export default class BookingReview extends Vue {
  preBooking_result = null
  image = null
  slip = ''
  gaBacK = false
  payments = []
  canSelectDate = true
  time_can_pay = null
  paymentType = 'credit'
  isBank = false
  isSubmit = false
  timeAt = Moment(60 * 60 * 1000)
  countDown = null
  image_2 = null

  // ------------------------
  isLoading = false
  isClickConfirmBooking = false
  couponStatusLabel = ''
  coupon = ''
  discount = 0
  paymentValue = {
    merchantid: MERCHANT_ID,
    refno: undefined,
    customeremail: undefined,
    productdetail: undefined,
  }
  async created() {
    if (!this.bookingReview.type) {
      let bookingState = JSON.parse(localStorage.bookingState)
      await this.setBookingReview(bookingState)
    }
  }
  toMyBooking() {
    this.setDiscountPrice(0)
    this.$router.push({ name: 'MyBooking' })
  }
  async toTop() {
    let result_3 = await this.preBooking(false, this.reduce)
    console.log('result bank ', result_3.data)

    if (result_3.data) {
      this.preBooking_result = result_3.data
      console.log('prebooking result', this.preBooking_result)
      const result = await this.uploadSlip({
        image: this.image_2,
        total: parseFloat(this.bookingReview.total),
        booking_id: this.preBooking_result.id,
      })
      // console.log("this.paymentBody.refno", this.paymentBody.refno);
      isProduction() &&
        this.$gtag.event('conversion', {
          send_to: 'AW-685115829/6OegCM_e6eIBELWT2MYC',
          transaction_id: this.paymentBody.refno || '',
        })

      console.log('presult.slip_url', result.slip_url)
      // this.image = convert;
      if (result.slip_url) {
        this.slip = result.slip_url
        this.isSubmit = true
        window.scrollTo(0, 0)
      }
    }
  }
  async mounted() {
    console.log('aaa', this.bookingReview)
    if (this.isLoggedIn) {
      await this.getPaymentHistory()
    }
    console.log(this.isLoggedIn)
    this.slip = this.slip_url
    // if (isUATEnv) {
    //   console.log("isUATEnv", isUATEnv);
    // }
    // console.log("localStorage.bookingState", localStorage.bookingState);
    // console.log("localStorage", localStorage);
    // let bookingState = JSON.parse(localStorage.bookingState);

    // console.log(bookingState);

    // if (!this.bookingReview.type) {
    //   this.$router.push({ name: 'Home' });
    // }

    // if (this.callBackPathAfterWelcomePage && this.isLoggedIn) {
    //   this.setCallBackPathAfterWelcomePage(""); // callback path should be clear after navigate success
    // }

    const router = this.$store.state.router
    // console.log("router", router.from.fullPath.includes("?"));
    router.from.fullPath.includes('?') ? (this.gaBack = true) : (this.gaBack = false)

    this.paymentValue.customeremail = this.email || 'noemail@bluuu.co'
    if (this.bookingReview.type === 'one-time') {
      if (this.bookingReview.method === 0) {
        this.paymentValue.productdetail = Constant.ONE_TIME_AUTO_SELECT
      } else {
        this.paymentValue.productdetail = Constant.ONE_TIME_MANUAL_SELECT
      }
    } else {
      this.paymentValue.productdetail = Constant.MULTI_PACKAGE
    }

    if (typeof this.bookingReview.coupon_body === 'object' && this.bookingReview.coupon_body) {
      this.bookingReview.coupon_body.booking_id = this.bookingReview.id
      this.bookingReview.coupon_body.used = true
      await ApiClient.applyCoupon(this.bookingReview.coupon_body)
    }

    //this.initMaps()
    this.bookingReview.total = this.bookingReview.amount
    //this.initMaps()
  }

  goBack() {
    this.$router.go(-1)
  }
  get isMobileXS() {
    let x = false
    screen.width < 425 ? (x = true) : (x = false)
    return x
  }

  get showApplyCouponSection() {
    return ENABLE_APPLY_COUPON
  }
  uploadImage() {
    document.getElementById('main_image').click()
  }
  compressImage(file) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.6,
        success(result) {
          resolve(result)
        },
        error(e) {
          reject(e)
        },
      })
    })
  }
  showImage(item) {
    if (this.slip) {
      this.slip = item
      this.image = item
    } else {
      this.image = item
    }
    this.$root.$emit('bv::show::modal', 'slip-img')
  }
  async importMainImage(e) {
    this.image = null
    this.slip = null
    const file = e.target.files[0]
    this.isLoading = true
    const convert = await this.compressImage(file)
    // console.log("file", file);

    // console.log("convert", convert);
    console.log(parseFloat(this.bookingReview.total))

    if (convert) {
      this.image = URL.createObjectURL(convert)
      this.image_2 = convert
      // const result = await this.uploadSlip({
      //   image: convert,
      //   total: parseFloat(this.bookingReview.total),
      //   booking_id: this.preBooking_result.id,
      // });
      // console.log("this.slip", result.slip_url);
      this.isLoading = false
      // this.image = convert;
      // this.slip = result.slip_url;
      // this.$store.dispatch("general/postMainImageContent", convert);
      // this.loader = true;
    }

    // this.url_main= URL.createObjectURL(file)
    // console.log(this.image_url);
  }

  requireAuthIfNotLoggedIn() {
    if (!this.isLoggedIn) {
      this.$root.$emit('bv::show::modal', 'require-auth-modal')
      return false
    }
    return true
  }

  async doSignIn() {
    // this.setCallBackPathAfterWelcomePage('/booking-review')
    this.$root.$emit('bv::hide::modal', 'require-auth-modal')
    // this.signIn()
  }
  async getPaymentHistory() {
    try {
      const result = await ApiClient.paymentHistory({
        page: 1,
        limit: 20,
      })
      const { payment } = result.data
      // console.log("paymrnt",payment);
      this.payments = payment[0]
      console.log('paymrnt', this.payments)
      console.log('this.$i18n.locale', this.$i18n.locale)
      const dateFormat = this.$i18n.locale === 'th' ? `DD MMM YYYY, H:mm` : `MMM DD, YYYY, H:mm`
      // let {time_pay, time_now} = null
      let time_pay = new Date(payment[0].create_datetime)
      let time_now = new Date()
      let time_can_pay = new Date(time_pay.getTime() + 60 * 60 * 24 * 1000)
      // console.log(`timepay:: ${time_pay}`);
      // console.log(`timenow:: ${time_now}`);
      // console.log(`timecanpay::${time_can_pay}`)
      // console.log(`moment time can pay::  ${Moment(time_can_pay).locale(this.$i18n.locale).format(dateFormat)}`)

      this.time_can_pay = Moment(time_can_pay).locale(this.$i18n.locale).format(dateFormat)
      this.$i18n.locale === 'th' ? this.time_can_pay + ' น.' : null
      if (time_now <= time_can_pay) {
        // ปิด function สำหรับทำรายการ 24 hr
        // this.canSelectDate = false
      } else {
        null
      }
      // console.log("can select", this.canSelectDate);
    } catch (e) {
      console.log(e.response)
    }
  }

  async doBooking() {
    // e.preventDefault();
    localStorage.paymentType = this.paymentType

    this.isClickConfirmBooking = true

    const canNext = this.requireAuthIfNotLoggedIn()
    let paymentBody = {
      ...clone(this.paymentBody),
      customeremail: this.$store.state.user.email || 'noemail@bluuu.co',
    }
    this.saveBookingDetailToLocalStorage()
    this.setPaymentBody(paymentBody)

    if (!canNext) return

    // this.isLoading = true
    // const isSuccess = this.isLoggedIn ? await this.$store.dispatch('auth/refreshToken') : true
    // this.isLoading = false
    // if (!isSuccess) {
    //   return
    // }

    await this.getUserProfile() // Ensure data payload
    paymentBody = {
      ...clone(this.paymentBody),
      customeremail: this.$store.state.user.email || 'noemail@bluuu.co',
    }
    this.setPaymentBody(paymentBody)

    this.saveBookingDetailToLocalStorage()
    this.savePaymentBodyToLocalStorage(paymentBody)

    if (this.bookingReview.total === 0) {
      this.$router.push({ name: 'PaymentResult' })
      return
    }

    this.isLoading = true
    console.log('Review', paymentBody)

    if (this.paymentType === 'credit') {
      if (this.is_verified_phone) {
        // console.log("can't book",this.canSelectDate);
        if (!this.canSelectDate) {
          this.$root.$emit('bv::show::modal', 'warning-cannotselectday')
          setTimeout(() => {
            this.$root.$emit('bv::hide::modal', 'warning-cannotselectday')
          }, 3000)
          this.processBooking(false)
        } else {
          // console.log("paymentBody", paymentBody);
          // await this.preBooking(false, this.paymentType);
          try {
            const result_2 = await this.preBooking(false, this.reduce)
            console.log('result PAY ', result_2.data)
            if (result_2.data.id) {
              const confirmPayment = {
                booking_id: result_2.data.id,
                ref_no: paymentBody.refno,
              }
              localStorage.confirmPayment = JSON.stringify(confirmPayment)
            }
            post(PAYSOLUTION_URL, paymentBody)
          } catch (e) {
            alert(e)
          }

          // post(PAYSOLUTION_URL, paymentBody);
          // if (this.isLog) {
          //   console.log("this.isLog", this.isLog);
          //   if (!paymentBody.merchantid && !paymentBody.refno) {
          //     this.$i18n.locale === "en"
          //       ? alert(
          //           "There was an error during in the booking process. Please take a screenshot and to Bluuu customer service"
          //         )
          //       : alert(
          //           "เกิดข้อผิดพลาดในการจอง กรุณาบันทึกภาพหน้าจอของคุณและติดต่อฝ่ายบริการลูกค้า Bluuu"
          //         );
          //     this.goBack();
          //   } else {
          //     const result_2 = await this.preBooking(false);
          //     console.log("result PAY ", result_2.data);
          //     post(PAYSOLUTION_URL, paymentBody);
          //   }
          // }
        } // redirect to paysolution
      } else {
        await this.processBooking(false)
        this.setPaymentBody(paymentBody)
        this.$router.push({ name: 'VerifyPhone' })
      } ///upload Slip
    } else {
      if (this.is_verified_phone) {
        // console.log("can't book",this.canSelectDate);
        if (!this.canSelectDate) {
          this.$root.$emit('bv::show::modal', 'warning-cannotselectday')
          setTimeout(() => {
            this.$root.$emit('bv::hide::modal', 'warning-cannotselectday')
          }, 3000)
          //  this.processBooking(false);
        } else {
          if (this.$i18n.locale == 'th') {
            Moment.locale(this.$i18n.locale)
            console.log('th')
          }

          // console.log("paymentBody", paymentBody);

          let a = false

          this.countDown = Moment(60 * 1000)
          this.timeAt = this.$momentTZ(this.$momentTZ().toISOString())
            .add(1, 'hours')
            .format('H:mm ddd MMM,YYYY')
          console.log('timeAt', this.$momentTZ().toISOString())
          this.isBank = true
          //   window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
          this.isLoading = false

          setInterval(() => {
            if (this.countDown.format('mm:ss') == '00:00' && a && !this.isSubmit) {
              if (a) {
                a = false
                this.$router.push({ name: 'TimeOut' })
              }
            } else {
              this.countDown = Moment(this.countDown.subtract(1, 'seconds'))
              a = true
            }
            // console.log("this.countDown", this.countDown.format("mm"));
          }, 1000)
        } // redirect to paysolution
      } else {
        await this.preBooking(false)
        // this.processBooking(false);
        this.setPaymentBody(paymentBody)
        this.$router.push({ name: 'VerifyPhone' })
      }
    }
  }
  get countDownHour() {
    // let time = null;
    // if (this.isBank) {
    //   time = Moment(60 * 60 * 1000);
    // } else {
    //   time = null;
    // }
    // console.log("countDownHour");
    if (this.$i18n.locale === 'en') {
      return `${this.countDown.format('mm:ss')} mins`
    } else return `${this.countDown.format('mm:ss')} นาที`
  }
  get cannotSubmit() {
    let cannotSubmit = false
    if (this.countDown.format('mm:ss') == '00:00') {
      cannotSubmit = true
    }
    return cannotSubmit
    // let time = null;
    // if (this.isBank) {
    //   time = Moment(60 * 60 * 1000);
    // } else {
    //   time = null;
    // }
    // console.log("countDownHour");
    // if (this.$i18n.locale === "en") {
    //   return `${this.countDown.format("mm")} mins`;
    // } else return `${this.countDown.format("mm")} นาที`;
  }

  @Watch('isLoggedIn')
  async onIsLoggedInChanged(newval) {
    if (newval && this.isClickConfirmBooking) {
      await this.getPaymentHistory()
      await this.doBooking()
    }
  }

  savePaymentBodyToLocalStorage(paymentBody) {
    window.localStorage.setItem('payment_body', JSON.stringify(paymentBody))
  }

  saveBookingDetailToLocalStorage() {
    this.paymentType === 'credit'
      ? (this.bookingReview.payment_status = 'ONLINE_BANKING_WAITING')
      : (this.bookingReview.payment_status = 'BANK_TRANSFER_WAITING')
    // console.log("this.bookingReview", this.bookingReview);
    window.localStorage.setItem('booking_detail', JSON.stringify(this.bookingReview))
  }

  get headerText() {
    return this.$t('booking_review.heading_2')
  }

  get confirmButtonText() {
    return this.bookingReview.isUpdateBooking
      ? this.$t('button.confirm_update')
      : this.$t('button.confirm_booking')
  }

  get isBookingOneTime() {
    return this.bookingReview.type === 'one-time'
  }

  get paymentBody() {
    const refno = Moment().format('X')
    const total = isProduction() ? this.totalString : '1'
    const { merchantid, customeremail, productdetail } = this.paymentValue
    const postbackParams = `?merchantid=${merchantid}&status=completed&refno=${refno}&customeremail=${customeremail}&productdetail=${productdetail}&total=${total}`

    // return { ...this.paymentValue, refno: Moment().format('X'), total: this.totalString }
    // const total = '1'
    if (this.bookingReview.isUpdateBooking) {
      return {
        ...this.paymentValue,
        refno: this.bookingReview.total > 0 ? refno : null,
        total,
        coupon: this.coupon,
        postbackurl: postbackurl + postbackParams,
        returnurl,
      }
    }
    return {
      ...this.paymentValue,
      refno,
      total,
      coupon: this.coupon,
      postbackurl: postbackurl + postbackParams,
      returnurl,
    }
  }

  get bookingType() {
    if (!this.bookingReview.type) return ''
    return this.bookingReview.type === 'one-time'
      ? this.$t('commons.book_one_time')
      : this.$t('commons.book_multi_package')
  }

  get additional() {
    const additionals = this.bookingReview.additional.map((val) => {
      return Constant.ADDITIONAL_INFO.find((option) => option.value === val).text
    })
    return additionals.map((text) => this.$t(`check_box.${text}`))
  }

  get amount() {
    return parseFloat(this.bookingReview.amount).toFixed(2)
  }

  get vat() {
    return (parseFloat(this.bookingReview.amount - this.discount_price) * 0.07).toFixed(2)
    //return parseFloat(0).toFixed(2) // fix me (use above value when we calculate total including vat)
  }

  get totalExcludeVat() {
    return (parseFloat(this.totalString) * 0.93).toFixed(2)
  }

  get totalDiscount() {
    return this.discount_price.toFixed(2)
  }

  get totalString() {
    const total = parseFloat(this.bookingReview.total * 1.07).toFixed(2)
    if(total != 0 && total != 0.00 && total != '0.00'){
      dataLayer.push({'event':'order_confirm','order_value':total});
    }
    return total
    //return parseFloat(this.bookingReview.total).toFixed(2) // fix me (Use above value)
  }

  initMaps(latitude, longtitude) {
    let lat = this.bookingReview.location.latitude
    let long = this.bookingReview.location.longitude
    // let center = new google.maps.LatLng(lat, long)

    // let maps = new google.maps.Map(this.$refs.gmaps, {
    //   zoom: 15,
    //   center: new google.maps.LatLng(lat, long),
    //   mapTypeId: google.maps.MapTypeId.ROADMAP,
    // })
    // let marker = new google.maps.Marker({
    //   position: center,
    //   map: maps,
    //   // visible: true, ไม่จำเป็นต้องเปิดก็ได้
    // })
    // marker.setMap(maps)
  }

  get fullName() {
    const maid = this.bookingReview.maid
    if (maid) {
      return maid.user.first_name + ' ' + maid.user.last_name
    }
    return ''
  }

  get service() {
    const serviceText = this.bookingReview.services ? this.bookingReview.services.text : ''
    if (this.bookingReview.calendar.length === 0) return serviceText
    const startDate = this.$momentTZ(this.bookingReview.calendar[0].start_datetime)
    const endDate = this.$momentTZ(this.bookingReview.calendar[0].end_datetime)

    // Fix me (services)  ${startDate.format('H:mm')} - ${endDate.format('H:mm')}
    return `${serviceText} `
  }

  get serviceTime() {
    if (this.bookingReview.calendar.length === 0) return serviceText
    const startDate = this.$momentTZ(this.bookingReview.calendar[0].start_datetime)
    const endDate = this.$momentTZ(this.bookingReview.calendar[0].end_datetime)

    return `${startDate.format('H:mm')} - ${endDate.format('H:mm')}`
  }

  get location() {
    const locationPin = this.bookingReview.location.main_text
    return `${locationPin} `
  }

  get place() {
    const secondPin = this.bookingReview.location.secondary_text
    return `${secondPin} `
  }

  get additional_details() {
    const additionalDetails = this.bookingReview.location.additional_details
    console.log('addigtoinal adial', additionalDetails)
    if (additionalDetails) {
      return `${additionalDetails}`
    }
    return null
  }

  get startDateLabel() {
    if (this.bookingReview.calendar.length === 0) return ``
    const date = Moment(this.bookingReview.calendar[0].start_datetime)
    const template = this.$i18n.locale === 'th' ? 'ddd DD MMM YYYY' : 'ddd MMM DD, YYYY'
    return date.format(template)
  }

  // discount for front only (ex Jan 31 2021)
  async applyCoupon() {
    if (this.coupon) {
      let date = new Date()
      let timestamp_now = date.getTime()
      let timestamp_end = 1614556800000 // 1 March2021
      let timestamp_end_pro20 = 1619755059000
      let timestamp_end_pro10 = 1625072399000
      console.log(timestamp_end_pro20)
      let getyears = Moment().format('YY')
      if (timestamp_now < timestamp_end) {
        if (this.coupon.toUpperCase() === 'WELCOME30') {
          this.discount = (this.bookingReview.amount * 30) / 100

          this.bookingReview.total = this.bookingReview.amount - this.discount
          this.couponStatusLabel = this.$t('booking_review.coupon_applied')
          this.saveBookingDetailToLocalStorage()
          // console.log(`discout: ${this.discount}, amount : ${this.bookingReview.amount}`)
        } else {
          this.discount = 0
          this.bookingReview.total = this.bookingReview.amount
          this.couponStatusLabel = this.$t('error.coupon.invalid_code')
          // console.log("else payment")
        }
      } else if (timestamp_now < timestamp_end_pro20) {
        if (this.coupon.toUpperCase() === 'WELCOME20') {
          this.discount = (this.bookingReview.amount * 20) / 100
          this.bookingReview.total = this.bookingReview.amount - this.discount
          this.couponStatusLabel = this.$t('booking_review.coupon_applied')
          this.saveBookingDetailToLocalStorage()
          // console.log(`discout: ${this.discount}, amount : ${this.bookingReview.amount}`)
        } else {
          this.discount = 0
          this.bookingReview.total = this.bookingReview.amount
          this.couponStatusLabel = this.$t('error.coupon.invalid_code')
          // console.log("else payment")
        }
      } else if (timestamp_now < timestamp_end_pro10) {
        if (this.coupon.toUpperCase() === 'WELCOME10') {
          this.discount = (this.bookingReview.amount * 10) / 100
          this.bookingReview.total = this.bookingReview.amount - this.discount
          this.couponStatusLabel = this.$t('booking_review.coupon_applied')
          this.saveBookingDetailToLocalStorage()
          // console.log(`discout: ${this.discount}, amount : ${this.bookingReview.amount}`)
        } else {
          this.discount = 0
          this.bookingReview.total = this.bookingReview.amount
          this.couponStatusLabel = this.$t('error.coupon.invalid_code')
          // console.log("else payment")
        }
      } else {
        this.discount = 0
        this.bookingReview.total = this.bookingReview.amount
        this.couponStatusLabel = this.$t('error.coupon.invalid_code')
      }
    } else {
      this.discount = 0
      this.couponStatusLabel = ``
      this.bookingReview.total = this.bookingReview.amount
    }
  }
  // Old applyCoupon
  // async applyCoupon() {
  //   if (this.coupon) {
  //     try {
  //       this.couponStatusLabel = ''
  //       const result = await ApiClient.applyCoupon({
  //         code: this.coupon.toUpperCase(),
  //         price: parseFloat(this.amount)
  //       })
  //       if (result.data) {
  //         // { "code": "ABC", "price": 5000, "used": false, "discount_price": 4950, "coupon_id": 1}
  //         const { price, discount_price, used } = result.data
  //         this.discount = price - discount_price
  //         this.bookingReview.total = discount_price
  //         this.couponStatusLabel = !used
  //           ? this.$t('booking_review.coupon_applied')
  //           : this.$t('booking_review.coupon_used')
  //         this.saveBookingDetailToLocalStorage()
  //       }
  //     } catch (err) {
  //       if (err.response.status === 400) {
  //         const { error } = err.response.data
  //         this.couponStatusLabel = this.$t(`error.coupon.${error}`)
  //         this.bookingReview.total = this.bookingReview.amount
  //         this.discount = 0
  //       }
  //     }
  //   } else {
  //     this.discount = 0
  //     this.couponStatusLabel = ``
  //     this.bookingReview.total = this.bookingReview.amount
  //   }
  // }

  get isApplySuccess() {
    return this.couponStatusLabel === this.$t('booking_review.coupon_applied')
  }

  get equipment() {
    const equipmentOption = this.getDefaultEquipmentOption({
      bring_equipment: get(this.bookingReview, 'equipment.bring_equipment', false),
    })

    const text = equipmentOption.text.split(') ')[1] // remove price (+xx THB)

    return { ...equipmentOption, text }
  }
}
</script>
<style scoped lang="scss">
.date,
.cleaning-type {
  margin: 0;
}
.fullname {
  margin-top: 20px;
}
.translateYg {
  transform: translateeY(100px) !important;
}

.text-black {
  color: #333333;
}
.description {
  word-wrap: break-word;
}

.avatar {
  width: 70px;
  height: 70px;
  border-radius: 64px;
}
.cc-number {
  height: 24px;
  display: flex;
  align-items: center;
}
.width_180 {
  width: 180px !important;
}
.width-image-modal {
  width: -webkit-fill-available;
  height: inherit;
}
.payment-detail {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin: 1em 0;
  font-size: 16px;
  color: #333333;
}
@media screen and (max-width: 1199px) {
  .input-text-area {
    &-1 {
      // display: none;
    }
  }
}
@media screen and (min-width: 1200px) {
  .input-text-area {
    &-2 {
      display: none;
    }
  }
}

@media screen and (min-width: 426px) {
  .input-text-area {
    &-2 {
      display: none;
    }
  }

  .img_rigth {
    position: relative;
    right: -50%;
  }
}

.map-container {
  width: 100%;
  display: flex;
}

.map-iframe {
  width: 79%;
  height: 350px;
  margin-left: 24px;
}

@media screen and (max-width: 1200px) {
  .map-iframe {
    width: 100%;
    margin-left: 0;
  }
}
</style>
